import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core'
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MtxLuxonDatetimeModule } from '@ng-matero/extensions-luxon-adapter'
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker'
import { DateTime } from 'luxon'
import { Subject, takeUntil } from 'rxjs'

@Component({
    selector: 'linkx-datetimepicker',
    standalone: true,
    imports: [CommonModule, MatInputModule, MtxDatetimepickerModule, MtxLuxonDatetimeModule, ReactiveFormsModule],
    templateUrl: './datetimepicker.component.html',
    styleUrls: ['./datetimepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatetimepickerComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatetimepickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() public label?: string
    @Input() public min?: DateTime
    @Input() public max?: DateTime
    public control: FormControl<DateTime | null> = new FormControl<DateTime>(DateTime.now())
    private _onChange!: (value: DateTime | null) => void
    private _onTouch!: () => void
    private _destroy$: Subject<void> = new Subject<void>()

    public ngOnInit(): void {
        this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((v) => {
            setTimeout(() => this._onChange?.(v))
        })
    }

    public ngOnDestroy(): void {
        this._destroy$.next()
        this._destroy$.complete()
    }

    public blur(): void {
        this._onTouch()
    }

    public writeValue(value: DateTime | null): void {
        this.control.patchValue(value, { emitEvent: false })
    }

    public registerOnChange(fn: (value: DateTime | null) => void): void {
        this._onChange = fn
    }

    public registerOnTouched(fn: () => void): void {
        this._onTouch = fn
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled && this.control.enabled) {
            this.control.disable()
        } else if (!isDisabled && this.control.disabled) {
            this.control.enable()
        }
    }
}
